import React from 'react'
import { Link } from 'gatsby'
import NoSSR from 'react-no-ssr';
import Layout from '../components/layout'
import { Helmet } from 'react-helmet'
import { formatDate } from '../helpers/dateHelper'
import { toLP } from '../helpers/gtagHelper'

import loadable from '@loadable/component'
const SEO = loadable(() => import(/* webpackChunkName: 'component--SEO' */ '../components/seo'))
const Tag = loadable(() => import(/* webpackChunkName: 'component--Tag' */ '../components/tag'))
const Sidebar = loadable(() => import(/* webpackChunkName: 'component--Sidebar' */ '../components/sidebar'))
const AsidePost = loadable(() => import(/* webpackChunkName: 'component--AsidePost' */ '../components/asidePost'))
const ArticleFooterBanners = loadable(() => import(/* webpackChunkName: 'component--articleFooterBanners' */ '../components/articleFooterBanners'))
const Sns = loadable(() => import(/* webpackChunkName: 'component--sns' */ '../components/sns'))
const Breadcrumb = loadable(() => import(/* webpackChunkName: 'component--breadcrumb' */ '../components/breadcrumb'))

const Post = ({ pageContext: { slug, title, tableOfContents, body, description, postTags, image, imageAlt, create, cta, relatedPosts, category, articlePath } }) => {
  const tagList = []
  for (let i in postTags) {
    tagList.push(
      <Tag key={i} tag={postTags[i]} />
    )
  }
  let ctaContent;
  if (cta === '100list') {
    ctaContent = (
      <div className="cta-document">
        <Link className="cta-document__wrap" to="/my-logue/doc_request">
          <div className="cta-document__top">
            <h3>無料資料<br className="d-none d-md-block" />ダウンロードフォーム</h3>
          </div>
          <div className="cta-document__contents">
            <h5 className="title">社内AIチャットボット導入に失敗しないための<br className="d-none d-md-block" />100のチェックリスト</h5>
            <p className="description">AIチャットボットの社内導入をご検討の際にご活用頂ける資料です。</p>
          </div>
          <div className="cta-document__btn">無料でPDFをダウンロード</div>
          <div className="document">
            <div className="document__wrap">
              <div className="document__title">社内AIチャットボット導入に失敗しないための100のチェックリスト</div>
              <div className="document__img" style={{ backgroundImage: "url('/images/img_doc_1.jpg')" }}></div>
            </div>
          </div>
        </Link>
      </div>
    )
  } else if (cta === 'inquiry-record-sheet') {
    ctaContent = (
      <div className="cta-document">
        <Link className="cta-document__wrap" to="/my-logue/doc_request">
          <div className="cta-document__top">
            <h3>無料資料<br className="d-none d-md-block" />ダウンロードフォーム</h3>
          </div>
          <div className="cta-document__contents">
            <h5 className="title">Q&amp;A収集用<br className="d-none d-md-block" />問い合わせ記録シート</h5>
            <p className="description">AIチャットボットの導入準備の際にご活用頂けるシートです。</p>
          </div>
          <div className="cta-document__btn">無料で問い合わせ記録シートをダウンロード</div>
          <div className="document">
            <div className="document__wrap">
              <div className="document__title">社内AIチャットボット導入準備用Q&A収集シート</div>
              <div className="document__img" style={{ backgroundImage: "url('/images/img_doc_2.jpg')" }}></div>
            </div>
          </div>
        </Link>
      </div>
    )
  } else if (cta === 'my-ope') {
    ctaContent = (
      <div className="cta-my-ope">
        <p className="cta-my-ope__description">My-ope officeは、人に代わって社内問い合わせ対応を担当する<br
          className="d-none d-md-block" />「人工知能チャットボット」が簡単に作れるクラウドシステムです</p>
        <a className="btn-outline" href="https://www.my-ope.net" target="_blank" rel="noopener noreferrer" onClick={toLP}>
          社内問い合わせ対応専用人工知能チャットボット My-ope office
        </a>
      </div>
    )
  }

  let categoryBreadcrumb
  if (!!category && !!category.category2) {
    categoryBreadcrumb = (
      <>
        <Breadcrumb name={category.category2.category1.name} slug={category.category2.category1.slug} />
        <Breadcrumb name={category.category2.name} slug={`${category.category2.category1.slug}/${category.category2.slug}`} />
        <Breadcrumb name={category.name} slug={`${category.category2.category1.slug}/${category.category2.slug}/${category.slug}`} />
      </>
    )
  } else if (!!category && !!category.category1) {
    categoryBreadcrumb = (
      <>
        <Breadcrumb name={category.category1.name} slug={category.category1.slug} />
        <Breadcrumb name={category.name} slug={`${category.category1.slug}/${category.slug}`} />
      </>
    )
  } else if (!!category) {
    categoryBreadcrumb = (
      <Breadcrumb name={category.name} slug={category.slug} />
    )
  }

  return (
    <Layout>
      <SEO title={title} description={description} type='article' image={image.fluid.src} url={articlePath} />
      <Helmet>
        <meta charset="utf-8" />
        <meta content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=0"
          name="viewport" />
        <meta content="width=device-width,initial-scale=1" name="viewport" />
        {<script id='pixel-script-poptin' src='https://cdn.popt.in/pixel.js?id=5fd6459d007de' defer='defer'></script>}
      </Helmet>
      <main className="main">
        <div id="fb-root"></div>
        <article className="article bg-light-gray">
          <div className="container">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb" style={{ "backgroundColor": "#f7f7f7" }}>
                <li className="breadcrumb-item"><Link to={`/my-logue`}>MyLogueTOP</Link></li>
                { categoryBreadcrumb }
              </ol>
            </nav>
          </div>
          <div className="container">
            <div className="article__content">
              <div className="article__left">
                <figure className="article__thumb">
                  <picture>
                    <source src={image.fluid.srcWebp} type="image/webp" />
                    <img
                      loading="lazy"
                      style={{"maxWidth": "630px", "maxHeight": "420px"}}
                      src={image.fluid.src}
                      title={image.title}
                      alt={image.description}
                    />
                  </picture>
                </figure>
                <div className="article__wrap">
                  <div className="article__inner">
                    <header className="article__header">
                      <div className="post__date mb-3">{formatDate(new Date(create))}</div>
                      <div className="tag-list">
                        {tagList}
                      </div>
                      <div className="article__title">
                        <h1>{title}</h1>
                      </div>
                      <NoSSR>
                        <Sns title={title} path={articlePath} />
                      </NoSSR>
                    </header>

                    <div dangerouslySetInnerHTML={{
                      __html: body
                    }} />
                  </div>
                  <NoSSR>
                    <Sns title={title} path={articlePath} />
                  </NoSSR>
                  <footer className="article__footer">
                    {ctaContent}
                    <ArticleFooterBanners />
                    <AsidePost posts={relatedPosts} />
                  </footer>
                </div>
              </div>
            </div>
            <Sidebar />
          </div>
        </article>
      </main>
    </Layout>
  )
}

export default Post
